// extracted by mini-css-extract-plugin
export var btnBackHome = "resume-module--btnBackHome--fe497";
export var container = "resume-module--container--1e970";
export var headerDesc = "resume-module--headerDesc--a8e6e";
export var headerDescResume = "resume-module--headerDescResume--ec858";
export var jobRow = "resume-module--jobRow--5b469";
export var linkIcon = "resume-module--linkIcon--535d2";
export var noBorder = "resume-module--noBorder--4f921";
export var pageContent = "resume-module--pageContent--88445";
export var pageHeader = "resume-module--pageHeader--4e616";
export var pageLogo = "resume-module--pageLogo--8729d";
export var pageNav = "resume-module--pageNav--e84eb";
export var pageTitle = "resume-module--pageTitle--d3cb7";
export var resumeContent = "resume-module--resumeContent--40c2a";
export var resumeLink = "resume-module--resumeLink--fbf4b";
export var resumeRow = "resume-module--resumeRow--488ee";
export var resumeWrap = "resume-module--resumeWrap--36a62";
export var section = "resume-module--section--82f5d";
export var sectionHeader = "resume-module--sectionHeader--d5807";
export var sectionHeaderResume = "resume-module--sectionHeaderResume--0ac15";
export var skillsRow = "resume-module--skillsRow--6f96a";
export var subTitle = "resume-module--subTitle--ce998";